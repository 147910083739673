import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { component$ } from '@builder.io/qwik';
import { twMerge } from 'tailwind-merge';
import type { QwikIntrinsicElements } from '@builder.io/qwik';

export type IconProps = {
  icon: IconDefinition;
  duotoneOpacity?: number;
} & QwikIntrinsicElements['svg'];

export const Icon = component$(
  ({ icon, duotoneOpacity = 0.4, ...props }: IconProps) => {
    const viewBox = '0 0 ' + icon.icon[0] + ' ' + icon.icon[1];
    const path = icon.icon[4];
    return (
      <svg
        {...props}
        class={twMerge('h-[1em] w-[1em]', props.class?.toString())}
        aria-hidden="true"
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
      >
        {Array.isArray(path) ? (
          path.map((p, i) => (
            <path
              key={i}
              d={p}
              fill="currentColor"
              fill-opacity={i === 0 ? duotoneOpacity : 1}
            />
          ))
        ) : (
          <path d={path} fill="currentColor" />
        )}
      </svg>
    );
  }
);
