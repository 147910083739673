import clsx from 'clsx';
import { Button } from './ui/Button';
import { Icon } from './ui/Icon';
import { Link } from '@builder.io/qwik-city';
import { component$ } from '@builder.io/qwik';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';

export interface PaginationProps {
  url: string;
  pagination: {
    totalCount: number;
    totalPages: number;
    currentPage: number;
    perPage: number;
  };
  pageUrlQueryParamName?: string;
  perPageUrlQueryParamName?: string;
  dots?: string;
  siblingCount?: number;
}

export const Pagination = component$(
  ({
    url,
    pagination,
    dots = '...',
    siblingCount = 1,
    pageUrlQueryParamName = 'page'
  }: PaginationProps) => {
    const paginationRange = usePagination({
      currentPage: pagination.currentPage,
      totalCount: pagination.totalCount,
      pageSize: pagination.perPage,
      siblingCount
    });

    const prevPage = pagination.currentPage - 1;
    const nextPage = pagination.currentPage + 1;

    const hasPrevPage = pagination.currentPage > 1;
    const hasNextPage = pagination.currentPage < pagination.totalPages;

    const getPath = (page: number) => {
      const urlObj = new URL(url);

      if (page === 1) {
        urlObj.searchParams.delete(pageUrlQueryParamName);
      } else {
        urlObj.searchParams.set(pageUrlQueryParamName, page.toString());
      }

      return `${urlObj.pathname}${urlObj.search}`;
    };

    if (pagination.currentPage === 0 || paginationRange.length < 2) {
      return null;
    }

    return (
      <nav class="flex items-center divide-x">
        <Link
          aria-label="prevpage"
          href={hasPrevPage ? getPath(prevPage) : undefined}
        >
          <Button
            class="rounded-r-none border-r-0 px-3 md:px-4"
            disabled={!hasPrevPage}
            variant="outline"
          >
            <Icon icon={faArrowLeft} />
          </Button>
        </Link>
        {paginationRange.map(pageNumber => {
          if (pageNumber === dots) {
            return (
              <Button
                key={pageNumber}
                class="pointer-events-none border-x-0 !rounded-none px-3 md:px-4"
                variant="outline"
              >
                &#8230;
              </Button>
            );
          }
          return (
            <Link key={pageNumber} href={getPath(Number(pageNumber))}>
              <Button
                class={clsx('border-x-0 rounded-none px-3 md:px-4', {
                  'shadow-inner shadow-black/10 bg-neutral-50':
                    pageNumber === pagination.currentPage
                })}
                variant="outline"
              >
                {pageNumber}
              </Button>
            </Link>
          );
        })}
        <Link
          class={`button icon-button ${hasNextPage ? '' : 'disabled'}`}
          aria-label="nextpage"
          href={hasNextPage ? getPath(nextPage) : undefined}
        >
          <Button
            class="rounded-l-none border-l-0 px-3 md:px-4"
            variant="outline"
          >
            <Icon icon={faArrowRight} />
          </Button>
        </Link>
      </nav>
    );
  }
);

const usePagination = ({
  totalCount = 0,
  pageSize = 1,
  siblingCount = 1,
  currentPage = 1,
  dots = '...'
}): (number | string)[] => {
  const totalPageCount = Math.ceil(totalCount / pageSize);
  const pageCount = siblingCount + 5;

  if (pageCount >= totalPageCount) {
    return range(1, totalPageCount);
  }

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
  const rightSiblingIndex = Math.min(
    currentPage + siblingCount,
    totalPageCount
  );

  const shouldShowLeftDots = leftSiblingIndex > 2;
  const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

  const firstPageIndex = 1;
  const lastPageIndex = totalPageCount;

  if (!shouldShowLeftDots && shouldShowRightDots) {
    const leftItemCount = 5 * siblingCount;
    const leftRange = range(1, leftItemCount);

    return [...leftRange, dots, totalPageCount];
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
    const rightItemCount = 5 * siblingCount;
    const rightRange = range(
      totalPageCount - rightItemCount + 1,
      totalPageCount
    );
    return [firstPageIndex, dots, ...rightRange];
  }

  if (shouldShowLeftDots && shouldShowRightDots) {
    const middleRange = range(leftSiblingIndex, rightSiblingIndex);
    return [firstPageIndex, dots, ...middleRange, dots, lastPageIndex];
  }

  return [];
};

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};
